import styled from "styled-components";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons//Menu";

export const t360LogoPurple = `${process.env.PUBLIC_URL}/assets/t360-header-logo-purple.svg`;
export const signoutIcon = `${process.env.PUBLIC_URL}/assets/signout-icon.svg`;

export const StyledMenuIcon = styled(MenuIcon)`
  margin-right: 10px;
  @media (min-width: 768px) {
    display: none !important;
  }
`;

export const Logo = styled.div<{ iconURL: string }>`
  width: 28px;
  height: 30px;
  display: inline-block;
  vertical-align: middle;
  background-image: ${(props) => `url(${props.iconURL})`};
  margin: 0 14px 0 0;
`;

export const Header = styled.div`
  margin-bottom: 50px;
`;

export const HeaderTitle = styled.div`
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.WHITE};
`;

export const Container = styled.div`
  padding: 32px 1px 0px 1px;
  height: 96.5vh;
  overflow: hidden;
  background-color: #4A82A6;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const MenuContainer = styled.div`
  margin-bottom: 60%;
`;

export const MenuLink = styled(Link)`
  text-decoration: none;
`;

export const MenuItem = styled.div<{ isActive?: boolean }>`
  display: flex;
  position: relative;
  align-items: center;
  color: #fff;
  background: ${({ isActive }) =>
    isActive ? "#a9bccc" : "#4A82A6"};
  height: 25px;
  padding: 8px 12px;
  margin: 0;
  cursor: pointer;
`;

export const MenuItemIcon = styled.div<{
  iconURL: string;
  isActive?: boolean;
  path?: string;
}>`
  width: 22px;
  height: 22px;
  mask: ${(props) => `url(${props.iconURL})`} no-repeat center;
  background-size: 20px;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: ${({ path, theme, isActive }) =>
    path === "signout"
      ? theme.colors.ACCENT[700]
      : isActive
      ? theme.colors.PRIMARY[400]
      : "#fff"};
`;

export const MenuItemText = styled.div`
  font-size: 16px;
  line-height: 24px;
  padding-left: 8px;
  font-weight: 500;
`;
