import { AppProvider } from "contexts/App";
import ThemeProvider from "theme";
import DynamicFavIcon from "components/DynamicFavIcon";
import { ModalProvider } from "contexts/AliceModalContext";
import { Auth0Provider } from "@auth0/auth0-react";
import AppRoutes from "routes/AppRoutes";
import { DOMAIN, CLIENT_ID, AUDIENCE } from "config/auth0Config";


function App() {

  return (
    <DynamicFavIcon>
      <Auth0Provider
          domain={DOMAIN.PROD}
          clientId={CLIENT_ID.PROD}
          cacheLocation="localstorage"
          authorizationParams={{
            redirect_uri: window.location.origin,
            audience: `${AUDIENCE.PROD}`,
            scope: "openid profile email"
          }}
        >
        <AppProvider>
          <ThemeProvider>
            <ModalProvider>
              <div className="App">
                <AppRoutes />
              </div>
            </ModalProvider>
          </ThemeProvider>
        </AppProvider>
      </Auth0Provider>
        
    </DynamicFavIcon>
  );
}

export default App;
