import styled from "styled-components";

export const DropDownContainer = styled.div`
  position: absolute;
  @media (max-width: 769px) {
    z-index: 1;
  }
  width: 100%;
  height: min-content;
  margin-top: 10px;
  background: ${({ theme }) => theme.colors.GREYED[800]};
  border: 1px solid ${({ theme }) => theme.colors.GRAY[300]};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
`;
export const DropDownCover = styled.div`
  width: 100%;
  height: min-content;
  cursor: pointer;
`;
export const DropDownItem = styled.div`
  padding: 10px 0 10px 14px;
  font-size: 12px;
  line-height: 24px;
  font-family: "Roboto Condensed", "sans-serif";
  color: ${({ theme }) => theme.colors.DARK[500]};
`;
