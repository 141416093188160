import {
    ReportHolder,
    ExecutiveSummary,
    ExecutiveSummaryTitle,
    ExecutiveSummaryContent,
    Field,
    Value,
    GroupDiv,
    ChartHolder,
    CircleHolder,
    TopContainer,
    ExecutiveSummaryP,
    NotVisibleDiv,
    hubwiseLogo,
    t360LogoPurple,
    HubwiseLogo,
    T360Logo,
    LogoHolder
} from "styles/client/Report.styled";
import React, {FunctionComponent, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { ReportSummaryType } from "types/funds";
import { Chart } from "react-google-charts";
import NoDataContent from "components/noData/NoDataContent";

const ReportSummary: FunctionComponent<{
    tableData: (string | { role: string; } | number)[][];
    reportSummaryData: ReportSummaryType | undefined;
    reportRef: React.RefObject<HTMLDivElement>
}> =  ({
    tableData,
    reportSummaryData,
    reportRef
}) => {
    const [fundManagerStats, setFundManagerStats]= useState<(string | number | null)[][]>([["Item", "Value"], ["SS&C Hubwise Active Fund Managers", 601], ["SS&C Hubwise Inactive Fund Managers", 102383]]);

    const options = {
        legend: { position: "none" },
        hAxis: {
            minValue: 0.1,
        },
        isStacked: true, // Stack bars horizontally
        colors: ["#3ebc77", "#84cefd", "#528cbc", "#937a46", "#51e48a"], // Bar colors 
    };

    const donutOptions = {
        legend: {
          position: "right",
        },
        pieSliceText: "percentage",
        slices: {
          0: { color: "#00c8c3", offset: 0.2 },
          1: { color: "#177f7a" },
        },
        tooltip: { isHtml: true },
      };

    return (
        reportSummaryData ? 
            <>
                <div>
                    <TopContainer >
                        <ExecutiveSummary>
                            <ExecutiveSummaryTitle>Executive Summary</ExecutiveSummaryTitle>
                            <ExecutiveSummaryContent>
                                <ExecutiveSummaryP>
                                    This report presents a detailed analysis of data changes over a specified time period. The objective is to provide key insights into how critical metrics have evolved, allowing management to track progress and make informed decisions.
                                </ExecutiveSummaryP>
                                <ExecutiveSummaryP>
                                    The analysis covers fund managers that are being added or removed along with their respective assets and updated assets information, comparing values across weekly intervals. 
                                </ExecutiveSummaryP>
                                <ExecutiveSummaryP>
                                    While no direct recommendations are included, the findings underscore essential trends and potential areas for deeper analysis. This report is intended to help the management team quickly identify areas of growth, decline, and stability across the evaluated metrics.
                                </ExecutiveSummaryP>
                                
                            </ExecutiveSummaryContent>
                        </ExecutiveSummary>
                        <CircleHolder>
                                <ExecutiveSummaryTitle>Fund Managers</ExecutiveSummaryTitle>
                                <Chart
                                    chartType="PieChart" // Donut is a PieChart with a pieHole
                                    data={fundManagerStats}
                                    options={donutOptions}
                                    width="100%"
                                    height="250px"
                                />
                            </CircleHolder>
                        
                    </TopContainer>
                    <ChartHolder>
                        <ExecutiveSummaryContent>
                            <ExecutiveSummaryTitle>Buylist Updates By Category</ExecutiveSummaryTitle>
                            {tableData.map((group, index)=>(
                                        index !== 0 &&
                                        typeof group[0] === "string" && typeof group[1] === "number" &&
                                            <GroupDiv key={index}>
                                                <Field>{group[0]}: </Field>
                                                <Value>{group[1]}</Value>
                                            </GroupDiv>
                                        
                            ))}
                        </ExecutiveSummaryContent>
                        <Chart chartType="BarChart" width="100%" height="400px"  data={tableData} options={options}/>
                    </ChartHolder>
                </div>
                <NotVisibleDiv ref={reportRef}>
                    <LogoHolder>
                        <HubwiseLogo iconURL={hubwiseLogo} />
                        <T360Logo iconURL={t360LogoPurple} />
                    </LogoHolder>
                    <TopContainer>
                        <ExecutiveSummary>
                            <ExecutiveSummaryTitle>Executive Summary</ExecutiveSummaryTitle>
                            <ExecutiveSummaryContent>
                                <ExecutiveSummaryP>
                                    This report presents a detailed analysis of data changes over a specified time period. The objective is to provide key insights into how critical metrics have evolved, allowing management to track progress and make informed decisions.
                                </ExecutiveSummaryP>
                                <ExecutiveSummaryP>
                                    The analysis covers fund managers that are being added or removed along with their respective assets and updated assets information, comparing values across weekly intervals. 
                                </ExecutiveSummaryP>
                                <ExecutiveSummaryP>
                                    While no direct recommendations are included, the findings underscore essential trends and potential areas for deeper analysis. This report is intended to help the management team quickly identify areas of growth, decline, and stability across the evaluated metrics.
                                </ExecutiveSummaryP>
                                
                            </ExecutiveSummaryContent>
                        </ExecutiveSummary>
                        <CircleHolder>
                                <ExecutiveSummaryTitle>Fund Managers</ExecutiveSummaryTitle>
                                <Chart
                                    chartType="PieChart" // Donut is a PieChart with a pieHole
                                    data={fundManagerStats}
                                    options={donutOptions}
                                    width="100%"
                                    height="250px"
                                />
                            </CircleHolder>
                        
                    </TopContainer>
                    <ChartHolder>
                        <ExecutiveSummaryContent>
                            <ExecutiveSummaryTitle>Buylist Updates By Category</ExecutiveSummaryTitle>
                            {tableData.map((group, index)=>(
                                        index !== 0 &&
                                        typeof group[0] === "string" && typeof group[1] === "number" &&
                                            <GroupDiv key={index}>
                                                <Field>{group[0]}: </Field>
                                                <Value>{group[1]}</Value>
                                            </GroupDiv>
                                        
                            ))}
                        </ExecutiveSummaryContent>
                        <Chart chartType="BarChart" width="100%" height="400px"  data={tableData} options={options}/>
                    </ChartHolder>
                </NotVisibleDiv>
            </>
           
        : <NoDataContent />
            
    );
}
  
export default ReportSummary;
  