import styled from "styled-components";
import DatePicker from "react-datepicker";
const editPenIcon = `${process.env.PUBLIC_URL}/assets/edit-pen.svg`;

export const hubwiseLogo = `${process.env.PUBLIC_URL}/assets/hubwise-logo-color.svg`;
export const t360LogoPurple = `${process.env.PUBLIC_URL}/assets/t360-header-logo-purple.svg`;

export const HubwiseLogo = styled.div<{ iconURL: string }>`
  width: 76px;
  height: 55px;
  display: inline-block;
  vertical-align: middle;
  background-image: ${(props) => `url(${props.iconURL})`};
  background-size: cover;
  margin: 0 14px 0 0;
  background-repeat: no-repeat;
`;

export const T360Logo = styled.div<{ iconURL: string }>`
  width: 50px;
  height: 55px;
  display: inline-block;
  vertical-align: middle;
  background-image: ${(props) => `url(${props.iconURL})`};
  background-size: cover;
  margin: 0 14px 0 0;
  background-repeat: no-repeat;
`;

export const LogoHolder= styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 60px;
`;

export const Container = styled.div`
  padding: 32px 32px 42px 32px;
  margin-bottom: 30px;
`;

export const Header = styled.div`
  margin-bottom: 32px;
`;
export const Title = styled.div`
  color: rgb(54, 64, 115);
  font-weight: 700;
  font-size: 36px;
  line-height: 42px;
`;
export const HeaderInfo = styled.div`
  font-family: "Roboto Condensed";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #475467;
`;
export const Content = styled.div``;
export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;
export const ReportTracker = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
  }
`;
export const LeftSection = styled.div`
  flex: 0.7;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 32px;
    display: flex;
    align-items: center;
  }
`;
export const RightSection = styled.div`
  flex: 0.3;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const Analytics = styled.div`
  height: 350px;
  box-shadow:
    0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;
  @media (max-width: 768px) {
    width: 420px;
    margin-right: 0;
  }
`;
export const AnalyticsHeader = styled.div`
  font-family: "Roboto Condensed";
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.02em;
  padding: 20px;
`;
export const CardInfoContainer = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 30px;
  @media (max-width: 768px) {
    gap: 10px;
    justify-content: center;
  }
`;

export const ClosingRate = styled.div`
  height: 307px;
  width: 90%;
  border-radius: 12px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
`;
export const RateHeaderText = styled.div`
  padding-top: 20px;
  padding-left: 20px;
  font-family: "Roboto Condensed";
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #2a2a2a;
`;
export const RateSmallText = styled.div`
  padding-left: 20px;
  font-family: "Roboto Condensed";
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #5b5b5b;
`;
export const TimeCardContainer = styled.div`
  width: 90%;
  margin-top: 24px;
`;
export const TopTimeCards = styled.div`
  display: flex;
  gap: 20px;
`;
export const BottomTimeCards = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 24px;
`;
export const Status = styled.div`
  margin-top: 25px;
  display: flex;
  width: 100%;
  gap: 40px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const StatusBox = styled.div`
  height: 162px;
  border: 1px solid #ffffff;
  box-shadow:
    0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
`;
export const StatusWrapper = styled.div`
  flex: 1;
`;
export const StatusItem = styled.div`
  text-align: center;
`;
export const StatusItemTitle = styled.div`
  text-align: center;
  padding-bottom: 16px;
  font-family: "Roboto Condensed";
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
`;
export const StatusImage = styled.img`
  padding-bottom: 12px;
  width: 95px;
  height: 87px;
`;
export const StatusInfo = styled.div<{ color: string }>`
  font-family: "Roboto Condensed";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({ color }) => {
    switch (color) {
      case "approved":
        return "#4CBB17";
      case "progress":
        return "#FF7800";
      case "denied":
        return "#D92D20";
      default:
        return "";
    }
  }};
`;
export const DocsSection = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
`;
export const DocItem = styled.div``;
export const DocTitle = styled.div`
  font-family: "Roboto Condensed";
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  word-wrap: break-word;
`;
export const DocInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  margin-top: 21px;
`;
export const DocImage = styled.img``;
export const DocNumber = styled.div`
  font-family: "Roboto Condensed";
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
`;
export const DownloadIcon = styled.img``;

export const FContainer= styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  
`;

export const FilterContainer= styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 30px;
  row-gap: 20px;
`;

export const StartDatePicker= styled(DatePicker)`
  width: 181px;
  height: min-content;
  background: ${({ theme }) => theme.colors.GREYED[800]};
  border: 1px solid ${({ theme }) => theme.colors.GRAY[300]};
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  padding: 10px;
  font-size: 15px;
  line-height: 17px;
`;
export const EndDatePicker= styled(DatePicker)`
  width: 181px;
  height: min-content;
  background: ${({ theme }) => theme.colors.GREYED[800]};
  border: 1px solid ${({ theme }) => theme.colors.GRAY[300]};
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  padding: 10px;
  font-size: 15px;
  line-height: 17px;
`;

export const FilterLabel= styled.label`
  font-size: 16px;
`;

export const GenerateReportBtnHolder= styled.div`
  padding-top: 30px;
`;

export const GenerateReportBtn= styled.button`
  width: 100%;
  width: 100%;
  height: 48px;
  background-color: ${(props) => `${props.type === "reset" ? "#CC0066" : "#2e1a61"}`};
  overflow: hidden;
  border-radius: 4px;
  color: #ffffff;
  border: none;
  font-size: 14px;
  font-weight: 700;
  margin: 10px 0;
  opacity: ${(props) => `${props.disabled ? 0.3 : 1}`};

  &:hover {
    cursor: ${(props) => `${props.disabled ? "not-allowed" : "pointer"}`};
    opacity: ${(props) => `${props.disabled ? 0.1 : 0.9}`};
  }
`;

export const ReportHolder= styled.div`
  margin-top: 30px;
  padding: 15px;
`;
export const ExecutiveSummary= styled.div``;
export const ExecutiveSummaryTitle= styled.div`
    font-size: 26px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.PRIMARY[400]};
    padding-bottom: 15px;
`;

export const ChartHolder= styled.div`
  display: grid;
  grid-template-columns: 40% 60% 1fr;
  gap: 10px;
`;
export const ExecutiveSummaryContent= styled.div`
  display: grid;
  grid-template-columns: repeat(1,1fr);
  gap: 0px;
  padding: 15px;
`;

export const ExecutiveSummaryP=styled.p`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
`;

export const CircleHolder= styled.div`

`;

export const TopContainer= styled.div`
  display: grid;
  grid-template-columns: 50% 50% 1fr;
`;

export const NotVisibleDiv= styled.div`
  position: absolute; 
  left: -9999px;
  margin-top: 30px;
  width: 100%;
`;

export const ReportDetailsContainer= styled.div`
  margin-top: 30px;
`;

export const DownloadBtn = styled.button`
  width: 121px;
  height: 32px;
  background: #2e1a61;
  border-radius: 2px;
  display: flex;
  align-items: center;
  font-family: "Roboto Condensed";
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: white;
  gap: 7px;
  border: none;
  outline: none;
  cursor: pointer;
  justify-content: center;
  float: left;
  margin-top: 22px;
`;

export const DetailSection= styled.div`
  margin-top: 40px;
`;

export const Field= styled.p`
  font-size: 16px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
`;

export const Value= styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.DARK[500]};
`;

export const GroupDiv= styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
`;

export const SummaryHolder= styled.div`
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(5,1fr);
  gap: 15px;
`;

export const DropDownName = styled.div`
  color: ${({ theme }) => theme.colors.DARK[500]};
  font-family: "Roboto Condensed", "sans-serif";
  font-size: 15px;
  line-height: 17px;
`;

export const DropDown = styled.div`
  width: 181px;
  height: min-content;
  background: ${({ theme }) => theme.colors.GREYED[800]};
  border: 1px solid ${({ theme }) => theme.colors.GRAY[300]};
  border-radius: 8px;
  cursor: pointer;
  padding: 2px;
`;

export const WrapTable = styled.div`
  white-space: nowrap;
  display: block;
  overflow: auto;
  max-height: 450px;
  @media print {
    max-height: none; /* Allow full height for tables */
    overflow-y: visible; /* Remove scrollbar */
  }
`;


export const RespTable = styled.div`
  width: 100%;
  display: table;
  border-collapse: collapse;
  // word-break: break-word;
`;

export const RespTableHeader = styled.div`
  display: table-header-group;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  background-color: rgba(224, 226, 236);
  word-break: break-word; // this is a temporary fixd for table width
  position: sticky;
  top: 0;
`;
export const TableHeaderCell = styled.div`
  display: table-cell;
  padding: 18px;
  max-width: 60px;
  overflow: hidden;
  width: min-content;

  &:first-child {
    padding-left: 40px !important;
  }
  &:last-child {
    padding-right: 40px !important;
  }
`;

export const RespTableBody = styled.div`
  display: table-row-group;
  padding: 0 40px;
`;

export const RespTableRow = styled.div<{ hasBorder?: boolean }>`
  display: table-row;
  border-bottom: ${(props) => (props.hasBorder ? "1px solid rgba(74, 130, 166, 0.5)" : "none")};
  font-size: 16px;
  cursor: pointer;
`;

export const TableBodyCell = styled.div`
  display: table-cell;
  padding: 18px;
  max-width: 60px;
  width: min-content;
  overflow: hidden;
  font-weight: 500;
  font-size: 13px !important;
  line-height: 16px;
  &:first-child {
    padding-left: 40px !important;
  }
  &:last-child {
    padding-right: 40px !important;
  }
  color: ${({ theme }) => (theme.colors.WHITE !== "#FFFFFF" ? "#fff" : theme.colors.DARK[400])};
  & a {
    color: inherit;
    &:visited {
      color: inherit;
    }
    &:hover {
      color: #364073;
      opacity: 0.9;
    }
  }
`;

export const TableContentContainer = styled.div<{
    noShadow?: boolean;
    noMarginTop?: boolean;
  }>`
    display: block;
    column-span: all;
    overflow-x: auto;
    margin-top: ${({ noMarginTop }) => (noMarginTop ? "" : "20px")};
    margin-bottom: 60px;
    background-color: ${({ theme }) => theme.colors.WHITE};
    border-radius: 8px;
    padding: 12px 0;
    padding-bottom: 37px;
    white-space: nowrap;
    &:last-child {
      margin-bottom: 0px;
    }
    &:first-child {
      margin-top: ${({ noMarginTop }) => (noMarginTop ? "" : "20px")};
    }
  `;