// Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

import { Link } from "react-router-dom";
import { CustomCardContainer, CustomCard } from "styles/landingPageCard/LandingPageCard.styled";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import { MouseEventHandler } from "react";
import { useAppData } from "../../hooks/useAppData";
import { LandingPageCardDetails } from "../../types";
import { PARTY } from "constants/index";

const LandingPageCard = ({
  landingPageCardDetails,
}: {
  landingPageCardDetails: LandingPageCardDetails;
}) => {
  const { status } = useAppData();
  const { track } = useTrackActivity();
  const { isUserPartOfOrganization } = useAppData();

  const trackClickActivity = (buttonName: string): MouseEventHandler<HTMLDivElement> => {
    return () => {
      track(ActivityType.BUTTON_CLICK, `${buttonName} page`, { button_clicked: buttonName });
    };
  };

  return (
    <CustomCardContainer onClick={trackClickActivity(landingPageCardDetails.text)}>
      <Link to={landingPageCardDetails.link} style={{ textDecoration: "none" }}>
        <CustomCard
          iconURL={landingPageCardDetails.iconURL}
          iconURLHover={landingPageCardDetails.iconURLHover}
          status={status}
        >
          <div>
            <span>&nbsp;</span>
          </div>
          <div>
            <p>{landingPageCardDetails.text}</p>
            {/* TODO: probably this should be removed in the future */}
            {landingPageCardDetails.text === "Fund Party Manager" && isUserPartOfOrganization([PARTY.HUBWISE]) && 
              <p>ENTER</p>
            }
          </div>
        </CustomCard>
      </Link>
    </CustomCardContainer>
  );
};

export default LandingPageCard;
