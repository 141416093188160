import { useLocation, Link } from "react-router-dom";
import useModal from "hooks/useModal";
import { PATH } from "constants/index";
import SignOut from "../alert/SignOut";

import {
  Container,
  Content,
  MenuContainer,
  MenuItem,
  MenuItemIcon,
  MenuItemText,
  MenuLink,
  Header,
  HeaderTitle,
  Logo,
  signoutIcon,
} from "../../styles/admin/Sidebar.styled";
import { ADMIN_MENU_ITEMS } from "../../constants/adminMenu";
import { useAppData } from "../../hooks/useAppData";

const isActive = (path: string, link: string) => {
  return path.includes(link);
};

function SideBar() {
  const { status } = useAppData();

  const t360LogoWhite =`${process.env.PUBLIC_URL}/assets/t360-header-logo.svg`;

  const { pathname } = useLocation();

  const signOutModal = useModal();

  const openSignOutModal = () => {
    signOutModal.open();
  };

  return (
    <Container>
      <SignOut controller={signOutModal} />
      <Content>
        <Header>
          <Link to={PATH.INDEX}>
            <Logo iconURL={t360LogoWhite} />
            <HeaderTitle>T360</HeaderTitle>
          </Link>
        </Header>
        <MenuContainer data-testid="menu-holder">
          {ADMIN_MENU_ITEMS.map((menu) => (
            <MenuLink to={menu.link} key={menu.text}>
              <MenuItem isActive={isActive(pathname, menu.link)}>
                <MenuItemIcon iconURL={menu.icon} isActive={isActive(pathname, menu.link)} />
                <MenuItemText>{menu.text}</MenuItemText>
              </MenuItem>
            </MenuLink>
          ))}
        </MenuContainer>

        {/* signout */}
        <MenuItem onClick={openSignOutModal} data-testid="sign-out">
          <MenuItemIcon iconURL={signoutIcon} path={PATH.SIGNOUT} />
          <MenuItemText>Sign Out</MenuItemText>
        </MenuItem>
      </Content>
    </Container>
  );
}

export default SideBar;
